<template>
  <TheHeader
    v-if="!hideHeader"
  />
  <router-view/>
</template>

<script setup>
import TheHeader from "@/components/TheHeader.vue";
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const hideHeader = computed(() => {
  return !!useRoute().query?.hideHeader
})
</script>
